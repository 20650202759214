import React, { useEffect, useState } from 'react';
import UserMasterHeader from './UserCommonComponent/UserMasterHeader';
import { Button, Col, Container, Row } from 'react-bootstrap';
import afterlogo from '../assets/login-after.png';
import QRlogo from '../assets/QRCode.jpeg';
import UserMasterFooter from './UserCommonComponent/UserMasterFooter';
import axios from 'axios';
import { PaymentAdd, RegistrationSelect } from '../AllApi/UserApi/UserApi';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';


const PaymentNewOnline = () => {

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const data = JSON.parse(sessionStorage.getItem("status"))
  const [Image, setImage] = useState(null);
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const userid = userdata.id
  const regid = userdata.reg_id
  const member_id = userdata.id
  const [transactionId, setTransactionId] = useState()
  const [amount, setAmount] = useState(1200);
  const [Userdata, setUserdata] = useState({});
  const currentDate = new Date();

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/");    
  }

  // const [isUpdate, setIsUpdate] = useState(false);

  const validateFileSize = (file, maxSizeMB, errorMessage) => {
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
      if (fileSizeInMB > maxSizeMB) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
    }
    return true;
  };


  const [open, setOpen] = useState(false);

  const hideModal = () => {
    setOpen(false);
    logoutFun(); 
  };


  const NewData = (e) => {
    e.preventDefault();
    // const userid = JSON.parse(sessionStorage.getItem("Userid"));
    if (!Image) {
      toast.error("Please select a photo before submitting.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if (!transactionId) {
      toast.error("Please Enter Transaction No before submitting.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    setIsLoading(true);

    if (!validateFileSize(Image, 1, 'Image size exceeds the maximum limit of 1MB.')) {
      setIsLoading(false);
      return;
    }

    let formData = new FormData();
    const status = "0"
    formData.append("file", Image);
    formData.append("member_id", userid);
    formData.append("taxid", transactionId);
    formData.append("pay_amount", amount);

    const member_id = formData.get("member_id");
    const taxid = formData.get("taxid");
    const pay_amount = formData.get("pay_amount");
    const file = formData.get("file");


    const finaldata = {
      member_id: member_id,
      file: file,
      taxid: taxid,
      pay_amount: pay_amount,
    }

  
// ==========post api===================
    axios
      .post(PaymentAdd, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        setIsLoading(false);
        if (res.data == "SAVED") {
          setOpen(true);

        }
        else if(res.data === "ALREADYEXIST"){
          toast.warn('Already Paid!! Wait for Admin approval!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });

  

  };


// select api=====================
  const getUserData = () => {

    axios.get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        const user = res.data[0];
        setIsLoading(false);
        if (user !== null) {
          setUserdata(user.data);

        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])


  const setImgfun = (e) => {
    // setupdateimage(false)
    setImage(e.target.files[0])
  }

//============QR img download====================
const downloadQRCode = () => {
  const link = document.createElement('a');
  link.href = QRlogo;
  link.download = <img src={QRlogo} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />;
  link.click();
};
//==========================================
  return (
    <Container fluid className='m-0 p-0'>
      <div className='m-0 p-0'>
        <UserMasterHeader />
      </div>

      <Container className='container-fourth rounded p-3 my-3 p-md-5 '>
        <div className=' rounded shadow  px-3 pb-2 py-sm-4' style={{ backgroundColor: "white" }} >
          <Row className='m-0'>
            <Col>
              <div className='mt-3 mt-md-0' style={{ display: "flex", justifyContent: "center", color: "rgb(134, 28, 18)" }}>
                <h5><b >Pay Your Registration Fee.</b></h5>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={afterlogo} />
              </div>
            </Col>
          </Row>

          <Row className='m-0 mt-4 mb-4 border shadow py-3'>
            <Col sm={12} md={5} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <img src={QRlogo} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />
              <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>Pay Amount Rs:</b></h6></Form.Label>
              <Form.Control
                className='w-50 text-center'
                value={amount}
                readOnly
              />

                 <Button className='mt-3 mt-md-2' style={{background:"rgb(134, 28, 18)",border:"0px"}} onClick= {downloadQRCode}>
                    Download QR Code
                  </Button>
            </Col>
            <Col sm={12} md={7} >

              <div className='row m-0 p-0 py-3'>
                    <div className='col-12 col-md-4'>
                        <b>Account Name :</b>
                    </div>
                    <div className='col-12 col-md-8'>
                        <span style={{fontSize:"20px"}}>SHETH RAMDHANAJI DAWADA JAIN BOARDING SANGLI</span>
                    </div>
              </div>

              <div className='row m-0 p-0 py-3'>
                    <div className='col-12 col-md-4'>
                        <b>Account No :</b>
                    </div>
                    <div className='col-12 col-md-8'>
                        <span style={{fontSize:"20px"}}>120503130002085</span>
                    </div>
              </div>

              <div className='row m-0 p-0 py-3'>
                    <div className='col-12 col-md-4'>
                        <b>UPI ID :</b>
                    </div>
                    <div className='col-12 col-md-8'>
                        <span style={{fontSize:"20px"}}>getepay.svccblqr432819@icici</span>
                    </div>
              </div>

              <div className='row m-0 p-0 py-3'>
                    <div className='col-12 col-md-4'>
                        <b>Bank Name/Branch :</b>
                    </div>
                    <div className='col-12 col-md-8'>
                        <span style={{fontSize:"20px"}}>SVC CO-OPERATIVE BANK LTD / SANGLI</span>
                    </div>
              </div>

              <div className='row m-0 p-0 py-3'>
                    <div className='col-12 col-md-4'>
                        <b>IFSC Code :</b>
                    </div>
                    <div className='col-12 col-md-8'>
                        <span style={{fontSize:"20px"}}>SVCB0000205</span>
                    </div>
              </div>


            </Col>
          </Row>

          <div className='row p-0 m-0 mb-2 py-2'>
              <div className='col-12 text-danger text-center'>
                    <b>After payment done, please upload the payment success screenshot & provide transaction details then Submit. <br/> नोंदणी शुल्क भरल्यानंतर, पेमेंट यशस्वी झालेल्याचे स्क्रिनशॉट येथे अपलोड करावा व ट्रांझॅक्शन डीटेल्स भरून सबमिट करा.</b>
              </div>
          </div>

          <Row>

            <Form
             
              encType="multipart/form-data">

              <Row className='px-4 mt-md-2'>
                <Col sm={12} md={6}>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>Payment Success Photo Upload :</b></h6></Form.Label>
                    <Form.Control
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => setImgfun(e)}
                      required
                      name="file"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>UTR No./UPI No./Transcation Transfer No:</b></h6></Form.Label>
                  <Form.Control
                    sm={12}
                    md={4}
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    required
                  />
                </Col>
              </Row>

              <Row>
              

            <div className="row p-0 m-0">
              <div className="col-md-4"></div>
              <div className="col-md-4" style={{display:"flex",justifyContent:"space-evenly"}}>
                {/* {isUpdate && (
                <Button
                  onClick={updatedata}
                  style={{
                    display: "flex",
                    backgroundColor: "rgb(134, 28, 18)",
                    border: "none",
                    justifyContent: "center",
                  }}
                  className="my-4 py-2 px-4"
                >
                  <b>{isLoading ?  "Updating..." : "Update"}</b>
                </Button>
              )} */}
               
              
                <Button
                  onClick={NewData}
                  style={{
                    display: "flex",
                    backgroundColor: "rgb(134, 28, 18)",
                    border: "none",
                    justifyContent: "center",
                  }}
                  className="my-4 py-2 px-4"
                >
                  <b>{isLoading ?  "Submitting..." : "Submit"}</b>
                </Button>
           </div>

              <div className="col-md-4"></div>
            </div>
           


          </Row>


            </Form>

          </Row>

          <Modal
            title="Payment Successful !"
            open={open}
            closable={false}
            footer={[
            <button style={{padding:"5px 15px",border:"1px solid",borderRadius:"5px",backgroundColor:"#790903",color:"white"}} key="ok" onClick={hideModal}>
              Ok
            </button>
          ]}  
          >
            <p className='text-danger'> नोंदणी शुल्क यशस्वीरीत्या भरले गेले आहे, ॲडमीन मंजुर करेपर्यंत प्रतीक्षा करा (किमान २४ तास).</p>
            <p className='text-danger'>Payment successfully submited, wait for admin approval, requires min 24 hrs.</p>
          </Modal>


        </div>
      </Container>
      <div><UserMasterFooter /></div>



    </Container>
  )
}

export default PaymentNewOnline

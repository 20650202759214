import React, { useEffect, useRef, useState } from 'react'
import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import { Col, Form, Row } from 'react-bootstrap'
import axios from 'axios';
import { dateWiseCandidateReportData, } from '../AllApi/AdminApi/AdminApi'
import { toast } from 'react-toastify'
import { DatePicker } from 'antd'
import dayjs from 'dayjs';

const AdminReportCount = () => {

  const dateFormatList = ['YYYY-MM-DD'];
  const [Userdata, setUserdata] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  
  const HandleSaveFun =(e)=>{
    e.preventDefault();

    if (!fromDate || !toDate) {
        toast.error("Please select both dates", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      const formattedFromDate = dayjs(fromDate).format('YYYY-MM-DD');
      const formattedToDate = dayjs(toDate).format('YYYY-MM-DD');

      console.log(`${dateWiseCandidateReportData}/${formattedFromDate}/${formattedToDate}`);
      
    axios.get(`${dateWiseCandidateReportData}/${formattedFromDate}/${formattedToDate}`)
      .then((res) => {
        
        if (res.data === "NOTFOUND") {
          toast.error("Data Not Found", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
         
        }
        else{
             setUserdata(res.data);           
        }
      })
      .catch((err) => {
        console.log(err)
      })
    
  }

const handleCancel =(e)=>{
    e.preventDefault(); 
    // window.location.reload()
    setFromDate(null);
    setToDate(null);
    setUserdata(null); 
}
  
  //--------------------------CSS---------------------------------
  // const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "12px" }
  const style = { fontSize: "12px", width: "100%", padding: "2px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "3px 5px", alignItems: "center", height: "65px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style6 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style5 = { display: "flex", lineHeight: "12px", fontSize: "12px", justifyContent: "center", flexDirection: "column" }

  return (
    <>
      <div> <AdminMasterHeader/> </div>
     
     <Row className="m-0 p-0 px-md-5 mx-md-5">
     <Col>
     <div className=' rounded shadow  p-3 m-md-5' style={{ backgroundColor:"white",  boxShadow:" 2px 2px 4px gray"}} >
   <Row>
   <Row className='m-0'>
      <Col className='text-center mb-2'>
        <h2 style={{color:"rgb(124, 58, 237)",fontFamily:"sans-serif",color: "rgb(134, 28, 18)"}}>Date Wise Candidate Report</h2>
      </Col>
      </Row>
      <Form >
        <Row className='m-0 '>
            <Col sm={12} md={3}></Col>

            <Col sm={12} md={3} className='mt-3 text-start'>
            <Form.Label><b>From Date : &nbsp;&nbsp;</b></Form.Label> 
                <DatePicker format={dateFormatList} className='w-100' value={fromDate ? dayjs(fromDate) : null}  onChange={(date, dateString) => setFromDate(dateString)}  />
            </Col>

            <Col sm={12} md={3} className='mt-3 text-start'>
            <Form.Label> <b>To Date : &nbsp;&nbsp;</b></Form.Label>
                <DatePicker format={dateFormatList} className='w-100'   value={toDate ? dayjs(toDate) : null}   onChange={(date, dateString) => setToDate(dateString)}  />
            </Col>

            <Col sm={12} md={3}></Col>
        </Row>

        <Row className='m-0 my-2'>
          <Col style={{display:"flex", justifyContent:"center"}}>

                  <button className='adminButton py-2 px-3 mx-2 mt-4 rounded' style={{
                      backgroundColor: "rgb(233, 224, 227)",
                      border: "0px",
                      color: "black",
                    }} type='submit' onClick={HandleSaveFun} ><b>Show Count</b>

                    </button> 

                    <button className='py-2 px-3 mx-2 mt-4 rounded' style={{
                      backgroundColor: "rgb(233, 224, 227)",
                      border: "0px",
                      color: "black",
                    }} onClick={handleCancel} ><b>Cancel</b>

                    </button> 

          </Col>



        </Row>

      </Form>
   </Row>
 </div>
     </Col>
   </Row>

   

   {/* ====================== data ==================== */}


   <div  id="content-to-print" style={{ width: "100%", height: window.innerHeight }}>
   
   {/* {
    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => { */}

    
        <>
        
        <div className='row m-0 pb-5'>
                     <div className='col-12 col-lg-4'></div>

                     <div className='col-12 col-lg-4 p-0 ps-4' style={{ backgroundColor: "white", overflow: "auto", }} >

                        <div className='row m-0 p-0 pb-5'>
                        <table className='shadow text-center'>
                            <thead>
                                <tr>
                                    <th className='text-center px-3' style={{border:"1px solid black",backgroundColor:"#660505",color:"white"}}>Date</th>
                                    <th className='text-center px-3' style={{border:"1px solid black",backgroundColor:"#660505",color:"white"}}>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {
                                    return (
                                <tr>
                                    <td className='text-center px-3' style={{border:"1px solid black"}}>{value.date} </td>
                                    <td className='text-center px-3' style={{border:"1px solid black"}}>{value.count} </td>
                                </tr>
                                    )
                            
                        })}
                            </tbody>
                        </table>
                            {/* <h5 className='text-center text-primary'><b>{value.date}</b></h5>
                            <h3 className='text-center text-primary'><b>{value.count}</b></h3> */}
                        </div>

                     </div>


                     <div className='col-12 col-lg-4'></div>
          </div>
        </>
      {/* )
    })} */}
    </div>

    <div> <AdminMasterFooter/> </div>
     
    </>
  )
}

export default AdminReportCount


import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { adminPaymentReportList } from '../AllApi/AdminApi/AdminApi';
import { Row, Table } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';

const AdminPaymentSummary = () => {
  const [record, setRecord] = useState([]);

  //----------------------------------API code------------------------------------------

  useEffect(() => {

   const fetchMemberData = async () => {
     try {
       const response = await axios.get(adminPaymentReportList);
       setRecord(response.data);
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };
 fetchMemberData()
}, [])

//=====================================DATA TABLE============================================

const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'fname',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Marital Status',
    field: 'marital_status',
    sort: 'asc',
  },
  {
    label: 'Disability',
    field: 'disability',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'emailid',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'Amount',
    field: 'amount',
    sort: 'asc',
  },
];


const customRows = record.map((item, index) => {
  const { gender, fname,marital_status,disability, mobileno, emailid,amount } = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  const disabled=disability === 1 ? 'No' : 'Yes';
  return {
    srNo: index + 1,
    fname,
    gender:genderLabel,
    marital_status,
    disability:disabled,
    mobileno,
    emailid,
    amount

  };
});
//=====================================================================================================
const footerStyle = {
  backgroundColor: '#660505',
  boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
  padding: '8px',
  textAlign: 'center',
  color: '#fff',
  fontSize: '12px',
  bottom: 0,
  width: '100%',
  letterSpacing: '1px',
  
}


 return (
   
   <>
   <div className="row m-0">
     <div className='p-0'> <AdminMasterHeader/> </div>
     <div className="filter-buttons row">
       <div className='col-12 ps-0'>
        <h2 style={{ fontWeight: '550',textAlign:"start"}}>Total Payment List</h2>
       </div>
     </div>
    
     {/* <Row className='row p-0 m-0 mb-5'>
      <div className='col-1'></div>
      <div  className='col-10'>
      <Table striped bordered hover className='photoApprove shadow' style={{fontSize:"14px"}}>
      <thead>
       <tr className='text-center'>
         <th>Sr.No</th>
         <th>Name</th>
         <th>Gender</th>
         <th>Marital Status</th>
         <th>Disability</th>
         <th>Mobile</th>
         <th>Transaction Id</th>
         <th>Amount</th>
       </tr>
     </thead>

     <tbody>
     {record.map((member,index) => (
       <tr className="text-center" key={index}>

         <td>{index + 1}</td>
         <td>{member.fname}</td>
         <td>{member.gender === 1 ? 'Male' : 'Female'}</td>
         <td>{member.marital_status}</td>
         <td>{member.disability === 1 ? 'No' : 'Yes'}</td>
         <td>{member.mobileno}</td>
         <td>{member.tnxid}</td>
         <td>Rs.{" "} {member.amount}</td>
       </tr>
       ))}
     </tbody>
       </Table>
      </div>
      <div className='col-1'></div>
     </Row> */}

       <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
          <div className="col-12 col-sm-1"></div>
          <div className="col-12 col-sm-10">
              <MDBDataTable
                className='mb-5 custom-datatable'
                striped
                bordered
                hover
                small
                data={{ columns, rows: customRows,}}
                style={{ textAlign: "center", fontSize: "14px" ,}}/>
          </div>
          <div className="col-12 col-sm-1"></div>
        </Row>

   </div>
     <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
   </>
 )
}
export default AdminPaymentSummary
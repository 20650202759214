import React, { useEffect, useState } from 'react'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import { EducationAdd, EducationDelete, EducationGetdata, EducationUpdate, SubeducationAdd, SubeducationDelete, SubeducationGetdata, SubeducationUpdate } from '../../AllApi/AdminApi/AdminApi'
import { Autocomplete, TextField } from '@mui/material'
import { MDBDataTable } from 'mdbreact';

const SubEducationMaster = () => {

    const [show, setShow] = useState(false);
    const [educationName, setEducationName] = useState();
    const [subeducationName, setSubeducationName] = useState();
    const [data, setData] = useState([]);
    const [isEditing, setisEditing] = useState(false);
    const [Id, setId] = useState();
    const [subId, setSubId] = useState(null);

    const [educationData, setEducationData] = useState([]);
  
    const handleClose = () => setShow(false);
    const [showEdit, setShowEdit] = useState(false);
  
    // =========== delete modal =========
   const [show1, setShow1] = useState(false);
   const handleClose1 = () => setShow1(false);
  
   const [itemToDelete, setItemToDelete] = useState(null);
  
   const handleShow1 = (item) => {
     setItemToDelete(item);
     setShow1(true);
   };  
  
  //  =========== Edit ==============
    const handleEditClose = () => {
      setShowEdit(false);
      setId('');
      setSubId('');
      setEducationName('');
      setSubeducationName('');
    };
  
    const handleEditShow = (item) => {
      setSubId(item.id);
      setEducationName(item.education_name);
      setSubeducationName(item.subeducation_name);
      setShowEdit(true);
      
    };
  
    const updateSubeducation = () => {
      const selectedEducation = educationData.find((edu) => edu.education_name === educationName);

      if (!selectedEducation) {
        console.error("Selected education not found!");
        return;
      }
     
      const formData = {
        educationMasterId: selectedEducation.id,
        subeducation_name: subeducationName,
      };
      axios
        .put(`${SubeducationUpdate}/${subId}`, formData)
        .then((res) => {

          if (res.data === "UPDATE") {
            
            toast.success("Data Updated Successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
  
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 2000);
  
            Getdata();
            handleEditClose();
          }
        })
        .catch((err) => {
          console.error("Error while updating Education:", err);
          if (err.response) {
            console.error("Response data:", err.response.data);
          }
    
          toast.error("Failed to update data, please try again!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    };
  
  //==================Save data Logic=================
  
      const savedata = (e) => {
      e.preventDefault();
      
      const obj ={
        educationMasterId: Id,
        subeducation_name: subeducationName,
      }

      axios
        .post(SubeducationAdd, obj)
        .then((res) => {
          if (res.data.message === "SAVED") {
            toast.success("Data Saved Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            // Reset form fields and reload the data
            Getdata();
            setEducationName('');
            setSubeducationName('');
            setId('');
            setShow(false);
          }
        })
        .catch((err) => {
          toast.error("Failed to upload, please try again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          console.error(err);
        });
      };
  
       //==================Getdata for table=================
  
       const Getdata = (e) => {
        axios  
          .get(`${SubeducationGetdata}`)
          .then((res) => {
            if (res.data !== null) {
              setData(res.data);

            }
            setisEditing(false);
          })
          .catch((err) => {
            toast.error("Error..!!!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            console.log(err);
          });
      };
    
          useEffect(() => {
              Getdata();
          }, [])
    
    
      //================delete data=============
  
      const onDelete = (item) => {
        axios  
          .delete(`${SubeducationDelete}/${item.id}`)
          .then((res) => {
            if (res.data === "DELETE") {
              toast.success("Data Deleted Succesfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
            else if (res.data === "NOTFOUND") {
              toast.warn("Data not found!!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
            handleClose1(false)
            Getdata();
          })
          .catch((err) => {
            toast.error("Failed to upload, try again", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            console.log(err);
          });
      };
    
   //=====================================================================
   useEffect(() => {
    if (isEditing === false) {
      Getdata();
      EducationListdata();
  
    }
  }, [isEditing]);

//====================get data for education==========================

 const EducationListdata = (e) => {
   axios  
     .get(EducationGetdata)
     .then((res) => {
       if (res.data !== null) {
        setEducationData(res.data);
       }
       setisEditing(false);
     })
     .catch((err) => {
       toast.error("Error..!!", {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
       });
       console.log(err);
     });
 };
  //================================table=================================
  const datatableData = {
    columns: [
      {
        label: 'Sr.No',
        field: 'srNo',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Id',
        field: 'id',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Subeducation Name',
        field: 'subeducation_name',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Education Name',
        field: 'education_name',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Edit',
        field: 'edit',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 150,
      },
    ],
    rows: data.map((item, index) => ({
      srNo: index + 1,
      id: item.id,
      subeducation_name: item.subeducation_name,
      education_name: item.education_name,
      edit: (
        <button
          className='m-1'
          style={{ color: 'orange', border: '0px', backgroundColor: 'transparent' }}
          onClick={() => handleEditShow(item)}
        >
          <i className='fa-solid fa-pen-to-square'></i>
        </button>
      ),
      delete: (
        <button
          className='m-1'
          style={{ color: 'red', border: '0px', backgroundColor: 'transparent' }}
          onClick={() => handleShow1(item)}
        >
          <i className='fa-solid fa-trash'></i>
        </button>
      ),
    })),
  };
    //==========================css====================
    const footerStyle = {
        backgroundColor: '#660505',
        boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
        padding: '8px',
        textAlign: 'center',
        color: '#fff',
        fontSize: '12px',
        bottom: 0,
        width: '100%',
        letterSpacing: '1px',
      }


  return (
      <>
    <div className="row m-0">
      <div className="p-0">
        {' '}
        <AdminMasterHeader />{' '}
      </div>

        <div className="filter-buttons row">
            <div className='col-12'>
                <h4 style={{ fontWeight: '550',textAlign:"start"}}>Subeducation Master</h4>
            </div>
        </div>

        <Row  className='m-0'>

{/* ====================== table =============== */}
<Row className='m-0  px-md-5  py-1 mb-5  w-100' style={{display:"flex"}}>

<Row className='m-0 mb-3' style={{display:"flex",justifyContent:"center"}}>
<Col md={3}></Col>
<Col md={6} style={{boxShadow:"2px 2px 10px grey",padding:"10px"}}>

<Form encType="multipart/form-data">

<Row className='m-0'>

  <Col sm={12} md={6} className='my-3'>
    <Form.Label><h6><b>Education Name :</b></h6></Form.Label>
    <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={educationData}
        getOptionLabel={(option) => option.education_name} 
        value={educationData.find((item) => item.id === Id) || null}
        onChange={(e, newValue) => setId(newValue ? newValue.id : '')}
        renderInput={(params) => <TextField {...params} label="Select Education" />}
    />
  </Col>

  <Col sm={12} md={6} className='my-3' style={{display:"flex",flexDirection:"column"}}>
   <Form.Label><h6 ><b>Subeducation Name :</b></h6></Form.Label>
    <TextField
      aria-label="Default select example"
      onChange={(e)=>setSubeducationName(e.target.value)}
      value={subeducationName}
      maxLength={40}
      required>
    </TextField>
  </Col>

</Row>

<Row className='m-0'>
        <Col md={3}></Col>
        <Col md={6} style={{display:"flex",justifyContent:"space-evenly"}}>
                     <Button style={{color:"black",border:"1px solid #660505",backgroundColor:"#e9e0e3" ,borderRadius:"5px"}}className='py-2 px-3 shadow' type='submit' onClick={savedata}>
                        Save 
                    </Button>
                
        </Col>
        <Col md={3}></Col>
           
</Row>

</Form>
</Col>
<Col md={3}></Col>

</Row>

<div style={{ overflowY: 'auto'}}>
<div style={{ overflowX: 'auto' }}>
  {/* <Table striped bordered hover size="sm" className='shadow mb-5 mt-2' style={{textAlign:"center"}}>
      <thead >
          <tr> 
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Sr.No</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Id</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Subeducation Name</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Education Name</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Edit</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Delete</th>
          </tr>
      </thead>
      <tbody>
        {data.map((item,index)=>{
          return(               
          <tr className='border' style={{fontSize:"14px"}}>
              <td style={{ textAlign:"center"}}>{index+1}</td>
              <td style={{ textAlign:"center"}}>{item.id}</td>
              <td style={{ textAlign:"center"}}>{item.subeducation_name}</td>
              <td style={{ textAlign:"center"}}>{item.education_name}</td>
              <td style={{textAlign:"center"}}>
             <button className=' m-1' style={{color:"orange",border:"0px", backgroundColor:"transparent"}}
               onClick={() => handleEditShow(item)}
             ><i class="fa-solid fa-pen-to-square"></i></button></td>
             <td style={{textAlign:"center"}}>
             <button className=' m-1' style={{color:"red",border:"0px", backgroundColor:"transparent"}}
             
             onClick={() => handleShow1(item)}
              ><i className="fa-solid fa-trash"></i></button></td>
          </tr>
           )
          })} 
      </tbody>
  </Table> */}
  {/* ====================== table =============== */}
       <Row className='m-0 mb-5'>
          <Col md={1}></Col>
          <Col md={10} style={{ boxShadow: '3px 3px 10px grey', padding: '10px' }}>
            <MDBDataTable
             striped
             bordered 
             hover
             data={datatableData} 
             className='custom-datatable' 
             paging={!showEdit}
             style={{ textAlign: "center", fontSize: "14px" ,}} 
            />
          </Col>
          <Col md={1}></Col>
        </Row>
  </div>
  </div>
</Row>




{/* =================== Edit Modal ======================== */}

<Modal show={showEdit} onHide={handleEditClose}>
   <Modal.Header className='m-0 p-0' style={{display:"flex",justifyContent:"center",color:"white",backgroundColor:"#660505"}} >
   <Modal.Title >
   <p style={{fontSize:"15px"}} className='text-center mt-2'><b>Subeducation Master</b></p>
   </Modal.Title>
 </Modal.Header>

<Modal.Body>
<Form encType="multipart/form-data">
<Row className='m-0'>
  <Col sm={12} md={12} className='mt-1'>
    <Form.Label><h6><b>Education Name :</b></h6></Form.Label>
        {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={educationData}
            getOptionLabel={(option) => option.education_name} // Assuming customer_name is the label
            value={educationData.find((item) => item.id === Id) || null}
            onChange={(e, newValue) => setId(newValue ? newValue.id : '')}
            renderInput={(params) => <TextField {...params} label="Select Education" />}
        /> */}
        <Autocomplete
            disablePortal
            disabled
            id="combo-box-demo"
            options={educationData}
            getOptionLabel={(option) => option.education_name}
            value={educationData.find((item) => item.education_name === educationName) || null}
            onChange={(e, newValue) => {
              setId(newValue ? newValue.id : null);
              setEducationName(newValue ? newValue.education_name : '');
            }}
            renderInput={(params) => <TextField {...params} label="Select Education" />}
          />

  </Col>
  <Col sm={12} md={12} className='mt-1'>
  <Form.Label><h6 ><b>Subeducation Name :</b></h6></Form.Label>
    <Form.Control
      aria-label="Default select example"
      onChange={(e)=>setSubeducationName(e.target.value)}
      value={subeducationName}
      required
      maxLength={40}
    >
    </Form.Control>
  </Col>

</Row>
</Form>
</Modal.Body>

 <Modal.Footer className='m-0 p-0'>
   <Button style={{color:"black",backgroundColor:"white",border:"1px solid grey",borderRadius:"5px"}} onClick={handleEditClose}>
     Cancel
   </Button>

       <Button style={{color:"black",backgroundColor:"#F1C40F",border:"none" ,borderRadius:"5px"}} className='py-2 px-3 mx-2  shadow' onClick={() => updateSubeducation(subeducationName)}>
         Update
       </Button>
     </Modal.Footer>
   </Modal>


   {/* ============= Delete Modal ========= */}

   <Modal show={show1} onHide={handleClose1}>
     <Modal.Header style={{backgroundColor:"#660505",color:"white"}} >
       <Modal.Title>Delete Confirmation</Modal.Title>
     </Modal.Header>
     <Modal.Body >Are you sure want to delete this item?</Modal.Body>
       <p style={{display:"flex",justifyContent:"end"}}>
       <Button className='me-2' style={{color:"black",backgroundColor:"white",border:"1px solid",borderRadius:"5px"}}  onClick={handleClose1}>
         Cancel
       </Button>
       <Button  style={{border:"none",backgroundColor:"red" ,borderRadius:"5px"}} className='me-2 shadow text-light' onClick={() => onDelete(itemToDelete)}>
         Delete
       </Button></p>
   </Modal>

</Row>

    </div>
    <div style={footerStyle} className='mt-5 fixed-bottom'>
      &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
    </div>
  </>
  )
}

export default SubEducationMaster
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../UserComponents/UserComponent.css';
import { useNavigate } from 'react-router-dom';
import { green } from '@mui/material/colors';
import afterlogo from '../assets/login-after.png';
import Card from 'react-bootstrap/Card';
import { RegistrationSelect, RegistrationStop } from '../AllApi/UserApi/UserApi';
import axios from 'axios';
import UserMasterHeader from '../UserComponents/UserCommonComponent/UserMasterHeader';
import UserMasterFooter from '../UserComponents/UserCommonComponent/UserMasterFooter';
import { toast } from 'react-toastify';
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import blink from '../assets/photo_rejected.gif'
import { memberImgPath, reportDataPDF } from '../AllApi/AdminApi/AdminApi';
import { Pagination } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const UserMemberProfiles = () => {

    const [Userdata, setUserdata] = useState([]);
   
    const [srNo, setSrNo] = useState(0);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); 
    const userdata = JSON.parse(sessionStorage.getItem("userdata"));
    const storedGender = userdata ? userdata.gender : null;
    
    const [gender, setGender] = useState(storedGender);
    const [selectedCategory, setSelectedCategory] = useState(null); 
    

     // Retrieve gender from session storage
     useEffect(() => {

        let token = sessionStorage.getItem("token");
        let userdata = sessionStorage.getItem("userdata");
    
        if (token == null || userdata == null) {
            sessionStorage.clear();
            navigate("/");
        }

    }, []);
  
     // Retrieve session date from session storage
     const sessionDateString = sessionStorage.getItem("paymentDate"); // Example key
     const fixedDateString = "2024-11-22"; // Your fixed date in YYYY-MM-DD format
   
     // Parse dates into Date objects for comparison
     const sessionDate = sessionDateString ? new Date(sessionDateString) : null;
     const fixedDate = new Date(fixedDateString);
   
     // Check if the session date is smaller than the fixed date
     const canEditProfile = sessionDate && sessionDate > fixedDate;
     const displayPrefix = sessionDate && sessionDate > fixedDate ? 'SUP2024/' : 'APP2024/';
  

     const HandleCategoryClick = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
        setLoading(true);
        setCurrentPage(1)
        const newGender = gender == '2' ? '1' : "2"; 
        setSrNo(0);
        
        axios.get(`${reportDataPDF}/${newGender}/${selectedCategory}/${srNo}`)
          .then((res) => {
            if (res.data === "NOTFOUND") {
                toast.error("Data Not Found", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setLoading(false);
                setUserdata([]); 
            } else {
                const data = res.data;
                setUserdata(data); 
                setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
            toast.error("Something went wrong!", { position: "top-right", autoClose: 2000 });
          });
    };
  
    useEffect(() => {
      let token=sessionStorage.getItem("token");
      let userdata=sessionStorage.getItem("userdata");
  
      if(token==null || userdata==null){
        sessionStorage.clear()
        navigate("/")
      }
    }, [])  
    

     // Get current data for the page
     const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     const currentData = Userdata.slice(indexOfFirstItem, indexOfLastItem);

     const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
  
    //--------------------------CSS---------------------------------
    const style1 = { lineHeight: "15px", fontSize: "14px" }
    const style3 = { lineHeight: "15px", fontSize: "14px" }
    const style = { fontSize: "14px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
    const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
    const style4 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "13px",wordWrap: 'break-word' }
    const style5 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "13px",flexDirection:"column",wordWrap: 'break-word' }
  //===========================================================================================


  //===========================================================================================

  return (
    <Container fluid className='m-0 p-0'>
    <div >
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
   
            <UserMasterHeader/>

                <Navbar expand="lg" style={{backgroundColor: "#660505"}}>
                    <Container fluid>
                        <Nav  className="ms-auto p-0">
                            <Nav.Link href="/#/mainPage" className=' mx-2 p-0 text-white fs-6'><b>Back</b></Nav.Link>
                        </Nav>
                    </Container>
            </Navbar>
     
     <div className='row m-0 p-2'>
        <div className='row m-0 shadow d-flex justify-content-center rounded' style={{backgroundColor:"#ededed"}}>
                <div className='col-12 col-md-3 p-2'>
                    <div className='p-1 rounded text-center' style={{backgroundColor: selectedCategory === 1 ? "#FFB200" : "#FABC3F",fontWeight:selectedCategory === 1 ? "bold" : "normal",border:selectedCategory === 1 ? "1px solid" : "1px solid grey",cursor:"pointer"}}  onClick={() => HandleCategoryClick(1)}>Medical</div>
                </div>
                <div className='col-12 col-md-3 p-2'>
                    <div className='p-1 rounded text-center'
                    style={{backgroundColor: selectedCategory === 2 ? "#E85C0D" : "#E38E49",fontWeight:selectedCategory === 2 ? "bold" : "normal",border:selectedCategory === 2 ? "1px solid" : "1px solid grey",cursor:"pointer"}}   onClick={() => HandleCategoryClick(2)}>Engineering</div>
                </div>
                <div className='col-12 col-md-3 p-2'>
                    <div className='p-1 rounded text-center'   style={{backgroundColor: selectedCategory === 3 ? "#A6B37D" : "#DCE4C9",fontWeight:selectedCategory === 3 ? "bold" : "normal",border:selectedCategory === 3 ? "1px solid" : "1px solid grey",cursor:"pointer"}} onClick={() => HandleCategoryClick(3)}>Graduates & Post Graduates</div>
                </div>
                <div className='col-12 col-md-3 p-2'>
                    <div className='p-1 rounded text-center'  style={{backgroundColor: selectedCategory === 4 ? "#DA7297" : "#F6D6D6",fontWeight:selectedCategory === 4 ? "bold" : "normal",border:selectedCategory === 4 ? "1px solid" : "1px solid grey",cursor:"pointer"}} onClick={() => HandleCategoryClick(4)}>Other</div>
                </div>
                <div className='col-12 col-md-3 p-2'>
                    <div className='p-1 rounded text-center' style={{backgroundColor: selectedCategory === 5 ? "#C8A1E0" : "#CDC1FF",fontWeight:selectedCategory === 5 ? "bold" : "normal",border:selectedCategory === 5 ? "1px solid" : "1px solid grey",cursor:"pointer"}}  onClick={() => HandleCategoryClick(5)}>Divorcee</div>
                </div>
                <div className='col-12 col-md-3 p-2'>
                    <div className='p-1 rounded text-center'  style={{backgroundColor: selectedCategory === 6 ? "#5B99C2" : "#C6E7FF",fontWeight:selectedCategory === 6 ? "bold" : "normal",border:selectedCategory === 6 ? "1px solid" : "1px solid grey",cursor:"pointer"}}  onClick={() => HandleCategoryClick(6)}>Widow</div>
                </div>
                <div className='col-12 col-md-3 p-2'>
                    <div className='p-1 rounded text-center'  style={{backgroundColor: selectedCategory === 7 ? "#FF885B" : "#FFE5CF",fontWeight:selectedCategory === 7 ? "bold" : "normal",border:selectedCategory === 7 ? "1px solid" : "1px solid grey",cursor:"pointer"}}  onClick={() => HandleCategoryClick(7)}>Disability</div>
                </div>
        </div>
     </div>

     <div className='row m-0 p-2 text-center'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
            <p className='rounded fw-bold w-100 pb-0' style={{padding:"5px 30px",backgroundColor:"#7c7c7c",color:"white"}}>{selectedCategory === 1 ? "MEDICAL" : selectedCategory === 2 ? "ENGINEERING" : selectedCategory === 3 ? "GRADUATES & POST GRADUATES": selectedCategory === 4 ? "OTHER": selectedCategory === 5 ? "DIVORCEE": selectedCategory === 6 ? "WIDOW": selectedCategory === 7 ? "DISABILITY" : ""}</p>
        </div>
        <div className='col-md-3'></div>
     </div>

        {/* ====================== data ==================== */}
        {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Spinner animation="border" variant="primary" />
        </div>
        ) : (
            <div  id="content-to-print" style={{ width: "100%",paddingTop:"10px" }}>
        
        {
            currentData  && Array.isArray(currentData ) && currentData .map((value, index) => {

            return (
                <>
                
                <div className='row m-0'>
                            <div className='col-12 col-lg-1'></div>

                            <div className='col-12 col-lg-10 p-0 px-md-3 mb-1' style={{ backgroundColor: "white",overflowX:"auto"}} >

                                <div className='p-2' style={{ backgroundColor: "#f796ec", display: "flex", alignItems: "center", width: "1160px" }}>
                                <div className='col-3'></div>
                                <div className='col-9 me-3' style={{ alignItems: "center", display: "flex" }}>
                                    <div className='col-9' style={{ margin: "0px 0px 0px -70px", alignItems: "end", }}><p style={{ fontWeight: "bold", fontSize: "20px", }}>{value.en_fname}/{' '}{value.en_fatherfname}{' '}{value.en_fathermname}{' '} {value.en_fatherlname} </p></div>

                                    <div className='col-3 me-5' style={{ textAlign: "center", padding: "6px", border: "2px solid white", borderRadius: "50px", backgroundColor: "white", margin: "0px 0px 0px 50px" }}><label style={{ fontWeight: "bold", }}>{value.gender === 1 ? 'APP2024/' : 'APP2024/'}{value.member_id}</label></div>
                                </div>
                                </div>

                                <div className=' d-flex p-0' style={{ border: "2px solid #c42fb2", borderTopColor: "#f796ec", width: "1160px", backgroundColor: "white" }}>
                                <div className='col-2 p-2' style={{ justifyContent: "center", display: "flex" }}>
                                    <img src={`${memberImgPath}/${value.image}`} alt={`${value.en_fname}'s Photo`} style={{ width: "100%", height: "200px", border: "1px solid grey" }} />
                                </div>

                                <div className='col-10 px-4 py-2'>

                                    <div className='row p-0'>
                                    <div className='col-8 p-0 pt-2' style={{ lineHeight: "10px" }}>
                                        <p style={{ fontSize: "15px", lineHeight: "18px" }}><b>Add : </b>{' '} {value.en_address}{' '}&nbsp;<b>Village/City : </b>{value.en_village}{' '}&nbsp;<b>Tal : </b>{value.talukaenname}{' '}&nbsp;<b>Dist : </b>{value.districtenname} {' '}&nbsp;<b>State : </b>{value.stateenname}</p>
                                        <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Mob : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                                        <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Occupation : </b>{' '}{value.occupation},&nbsp;{value.occupation_detail}</p>
                                    </div>

                                    <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
                                        
                                            <div className='d-flex mb-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                            <div style={{ padding: "10px 15px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Brother</b></div>

                                            <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                                    
                                            
                                                <span style={{ fontSize: "13px" }}><b>Married- </b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                                
                                                
                                                <span style={{ fontSize: "13px" }} className='ms-4'><b> Unmarried- </b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                            

                                        
                                            </div>
                                            
                                            </div>
                                        

                                        <div className='d-flex mt-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                            <div style={{ padding: "10px 10px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Sister</b></div>

                                            <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                                                <span style={{ fontSize: "13px" }}><b>Married- </b>{value.s_married > 0 ? value.s_married: '-' }</span>
                                                <span style={{ fontSize: "13px" }}  className='ms-4'><b> Unmarried- </b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span> 
                                            </div>

                                        </div>
                                        
                                    </div>
                                    </div>


                                    <div className="row p-0 d-flex mt-2">
                                    <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                        <div style={style}><b>DOB & Time</b></div>
                                        <div style={style2}> <p style={style4}>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                                    </div>

                                    <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                        <div style={style}><b>Height & Comp.</b></div>
                                        <div style={style2}> <p style={style4}>{value.height}</p> <p style={style4}>{value.complextion}</p></div>
                                    </div>

                                    <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                        <div style={style}><b>Education</b></div>
                                        <div style={style2}> <h6 style={style5} className='text-center'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                                        </div>
                                    </div>

                                    <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                        <div style={style}><b>Annual Income & Assets</b></div>
                                        <div style={style2}> <h6 style={style4}>{value.amount}</h6>
                                        { value.farm_g > 0 || value.farm_ac > 0 ? (
                                            <>
                                            <h6 style={style4}><b>Farm -&nbsp;</b>
                                            {value.farm_ac > 0 ? (
                                            <span> {value.farm_ac}&nbsp; acre{' '}</span>
                                            ) : null}
                                            {value.farm_g > 0 ? (
                                                <span style={style3}>{' '}&nbsp; {value.farm_g} &nbsp; gunthe{' '}</span>
                                                ) : null
                                            }
                                            </h6>
                                            </>
                                        ) : null}

                                        <span className='text-center' style={style4}>{value.other_property}</span></div>
                                    </div>


                                    <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"93px"  }}>
                                        <div style={style}><b>SubCaste </b></div>
                                        <div style={style2}> <p style={{lineHeight:"18px",fontSize:"13px"}}>{value.subcastename}</p></div>
                                    </div>

                                    <div className='p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"108px" }}>
                                        <div style={style}><b>Expectations</b></div>
                                        <div style={style2}> <p style={{lineHeight: "20px", fontSize: "13px",display:"flex",flexDirection:"column",textAlign:"center",margin:"0px 12px",flexWrap:"wrap"}}>{' '}{value.exp_housewife === 1 ? 'Housewife ' : null}{value.exp_compatible === 1 ? 'Compatible ' : null}{value.exp_farmer === 1 ? 'Farmer ' : null}{value.exp_serviceman === 1 ? 'Service ' : null}{value.exp_businessman === 1 ? 'Business ' : null}</p></div>
                                    </div>

                                    </div>
                                </div>
                                </div>

                            </div>


                            <div className='col-12 col-lg-1'></div>
                        </div>
                </>
            )
            })}


            </div>
        )}

        {/* =======================Pagination controls======================= */}

        <div className='row m-0'>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <Pagination style={{maxWidth:"350px",overflowX:"scroll"}}>
                        {Array.from({ length: Math.ceil(Userdata.length / itemsPerPage) }, (_, index) => (
                            <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </div>
        </div>

  </Container> 
  </div>

    {/* <div><UserMasterFooter /></div> */}
  </Container>
  )
}

export default UserMemberProfiles
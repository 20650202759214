// export const BaseIP = 'http://192.168.29.112:8091/';

export const BaseIP = 'https://registration.jainboardingsangli.com/';

export const adminLogin= BaseIP+ "api/admin_logins/adminlogin" // Admin login 
export const adminDashboard= BaseIP+ "api/member_logins/DashBoard" // Admin Dashboard
export const adminDashboardEducation=BaseIP+"api/admin_logins/educationCategory"// Admin Dashboard Education Category
export const adminMemberList= BaseIP+ "api/registration/select/list" // Admin member profile list
export const adminPhotoApproveList=BaseIP+"api/admin_logins/photoApprovalList"// Admin Photo Approve list
export const adminDisabilityList=BaseIP+"api/admin_logins/disabilityMemberList"// Admin Disability list
export const adminPhotoApproval=BaseIP+'api/admin_logins/photoStatusSaveApproval'   //Admin photo status approval
export const adminDisabilityApproval=BaseIP+'api/admin_logins/disabilityApproval'   //Admin disability approval
export const adminInactiveMemberList=BaseIP+'api/admin_logins/inActiveMemberList'  //Admin inactive member list
export const adminPaymentPendingList=BaseIP+'api/admin_logins/paymentPendingList'  //Admin Payment pending list

export const adminPaymentPendingListOnline=BaseIP+'api/manual_payment/paymentPendinglist'  //Admin Payment pending list Online (new)
export const adminPaymentSuccessList=BaseIP+'api/admin_logins/paymentSuccessList'  //Admin Payment success list
export const adminUpdateProfile=BaseIP+'api/member_logins/update/adminMemberProfile'  //Admin update profile (for edit page)
export const RegistrationSelect=BaseIP+"api/registration/select"//Personal detail api (for edit page)
export const ApprovalViewDetails=BaseIP+"api/manual_payment/select"//Personal detail api (for edit page)
export const adminPaymentApproval=BaseIP+'api/manual_payment/paymentApproval'   //Admin payment approval (status change -(approve-reject)) (put)
export const adminPaymentApprovePending=BaseIP+'api/manual_payment/findall'   //Admin payment approval) (get)

export const adminPaymentReportList=BaseIP+'api/admin_logins/paymentSuccessList/Report'  //Admin payment report list


export const reportDataPDF=BaseIP+'api/registration/reportData' //Admin inactive list destroy

export const dateWiseCandidateReportData=BaseIP+'api/registration/findRegistrationCountByDate' //Admin Date wise candidate count report
export const findBookIssuedList=BaseIP+'api/registration/reportData/findBookIssuedList' //Admin Date wise candidate count report


export const adminInactiveDestroy=BaseIP+'api/admin_logins/listDestroy' //Admin Report API

export const PaymentApproveSave=BaseIP+"api/adminPayment/add"//Payment Approve  save api
export const PaymentApproveList=BaseIP+"api/adminPayment/findall"//Payment Approve list api


//============admin side new entry masters api================
export const EducationMaster= BaseIP+ "api/education/select" //Education Master
export const SubeducationMaster= BaseIP+ "api/subeducation/select" //sub-Education Master
export const subcastMaster= BaseIP+ "api/subcast/select" // SubCast master
export const IncomeMaster= BaseIP+ "api/income/select" // Income master
export const StateMaster= BaseIP+ "api/state/getState" // State master
export const DistrictMaster= BaseIP+ "api/district/getDistrict" // District master
export const TalukaMaster= BaseIP+ "api/taluka/getTaluka" // Taluka master

//============admin side new entry================
export const RegsitrationSave=BaseIP+"api/member_logins/adminMemberCreate"// registration Save api
export const RegistrationUpdate=BaseIP+"api/registration/update" // checkbox api
export const RegistrationUpload=BaseIP+"api/registration/upload"//photo Upload api
export const PaymentAdd=BaseIP+"api/manual_payment/add"//payment add api


//==========================MASTERS===============================

//=======Education Master============ 
export const EducationAdd=BaseIP+"api/education/add"  // education master save data
export const EducationGetdata=BaseIP+"api/education/select"  // education master get data
export const EducationUpdate=BaseIP+"api/education/update"  // education master get data
export const EducationDelete=BaseIP+"api/education/delete"  // education master delete data

//=======Subeducation Master============ 
export const SubeducationAdd=BaseIP+"api/subeducation/add"  // Subeducation master save data
export const SubeducationGetdata=BaseIP+"api/subeducation/findall"  // Subeducation master get data
export const SubeducationUpdate=BaseIP+"api/subeducation/update"  // Subeducation master get data
export const SubeducationDelete=BaseIP+"api/subeducation/delete"  // Subeducation master delete data

//=======Salary Master============ 
export const SalaryAdd=BaseIP+"api/income/add"  // Salary master save data
export const SalaryGetdata=BaseIP+"api/income/select"  // Salary master get data
export const SalaryUpdate=BaseIP+"api/income/update"  // Salary master get data
export const SalaryDelete=BaseIP+"api/income/delete"  // Salary master delete data

// ======IMG PATH===========
export const memberImgPath=BaseIP+"Server/uploads"  // User Image Path
export const paymentImgPath=BaseIP+"Server/manual_paymentImage"  // User Payment Image Path


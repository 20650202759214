
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../BarCodeLogin/LoginQr.css"
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import beforelogo from '../assets/registration-before.png';
import afterlogo from '../assets/login-after.png';
import Barcode from '../assets/QRCode.jpeg';
import { BarcodeScanPage, EntryDetail, Getattendancedata, RegistrationSelect } from '../AllApi/UserApi/UserApi';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../AdminComponents/Members/Members.css'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import { adminPaymentSuccessList } from '../AllApi/AdminApi/AdminApi';
import { MDBDataTable } from 'mdbreact';
import * as XLSX from 'xlsx';

const ScanPage = () => {

     // Scroll to the top when component is mounted
     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    
   //===================for download the excel sheet======================
   const handleDownload = () => {
    const filteredUserdata = Userdata.filter((value) => value.No_of_person <= 5);
  
    const ws = XLSX.utils.json_to_sheet(filteredUserdata);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const fileName = 'Attendance Sheet.xlsx';
    XLSX.writeFile(wb, fileName);
  };
  
//================================================================================

  const location=useLocation()
  const [Reg_id, setReg_id] = useState()

  const navigate = useNavigate()
  const [record, setRecord] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/loginBarCode");    
  }

  const handleShowLogoutModal = () => setShowLogoutModal(true);
  // Function to hide the modal without logging out
  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  // Function to confirm logout and clear the session
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseLogoutModal();
  };


  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/loginBarCode')
    }
  }, [isLoggedIn, navigate])

  // ========= search api code============
    //----------------------------------API code------------------------------------------

    useEffect(() => {
      const tokenValue = sessionStorage.getItem('token')
  
      const tokenConfig = {
        headers: {
          token: tokenValue,
        },
      }
  
      const fetchMemberData = async () => {
        try {
          const response = await axios.get(
            adminPaymentSuccessList,
            tokenConfig);
          setRecord(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    fetchMemberData()
  }, [])


     //=====================================DATA TABLE============================================
  
  
  const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Reg.id',
      field: 'reg_id',
      sort: 'asc',
    },
    {
      label: 'MemberId',
      field: 'member_id',
      sort: 'asc',
    },
    {
      label: 'Name',
      field: 'name_en',
      sort: 'asc',
    },
    {
      label: 'Gender',
      field: 'gender',
      sort: 'asc',
    },
    {
      label: 'Marital Status',
      field: 'marital_status',
      sort: 'asc',
    },
    {
      label: 'Email Id',
      field: 'emailid',
      sort: 'asc',
    },
    {
      label: 'Mobile No',
      field: 'mobileno',
      sort: 'asc',
    },
  ];
  
  
  const customRows = record.map((item, index) => {
    const { gender,reg_id,member_id, name_en,marital_status, mobileno, emailid, } = item;
    const genderLabel = gender === 1 ? 'Male' : 'Female';
    
    return {
      srNo: index + 1,
      reg_id,
      member_id,
      name_en,
      gender:genderLabel,
      marital_status,
      mobileno,
      emailid,
    };
  });

  //send data page code
  const scanid = () => {
 
    axios.get(`${Getattendancedata}${Reg_id}`)
    .then((res) => {
      if (res.data.message === 'ALREDYEXIST') { 
        navigate("/alreadyDonePage",{ state: { Reg_id } })
      }
      else if(res.data.message === 'NOTFOUND'){
        toast.warn('Data not found!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      else {
        navigate("/dataPage",{ state: { Reg_id } })
      }
    })
    .catch((err) => {
      console.log(err)
    })   
  }

  // ============api Entry details============
  


  const [Userdata, setUserdata] = useState()


  const getUserData = () => {


    axios.get(EntryDetail)
      .then((res) => {
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])

   //--------------------------CSS---------------------------------
   const style1 = { lineHeight: "15px", fontSize: "14px" }
   const style3 = { lineHeight: "15px", fontSize: "14px" }
   const style = { fontSize: "14px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
   const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
   const style4 = { display:"flex",lineHeight: "15px", fontSize: "14px",justifyContent:"center" }
   const style5 = { display:"flex",lineHeight: "15px", fontSize: "14px",justifyContent:"center",flexDirection:"column" }


  return (
    <Container fluid className='p-0 m-0 ' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col>

        <Col md={1} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <Nav.Link  onClick={handleShowLogoutModal}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row>  


      <Row className='mb-3 m-0 mt-md-4'>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
            <img src={beforelogo}  />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 className='mt-1 mb-0 pb-1 text-center' style={{ color: "rgb(134, 28, 18)" }}><b>Function Entry / Book Issue</b></h2>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={afterlogo} />
        </div>
     </Row>

     <Row className='m-0 pt-1 pt-md-5 mb-5 mb-md-0'>
        <Col md={2}>
        <div className='ps-md-5 m-0'>
        <button  className='scanBtn py-2 px-3 rounded'  style={{backgroundImage:"linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",border:"1px solid",color:"white"}} onClick={handleShow}>
        <b>Search Here</b>
      </button>
        </div>
        </Col>

        <Col md={8} sm={12} style={{display:"flex"}} className=''>
        <Col sm={12} className=' d-flex align-items-center justify-content-center pb-5'>
        
          <div className='me-5'>
          <button style={{backgroundColor:"rgb(136, 232, 112)"}} onClick={scanid} className='scanBtn py-2 px-3 rounded '>
            <b>Scan Here</b>
          </button><span><i class="fa-solid fa-arrow-right"></i></span>
          </div>
   
          <input className='shadow py-2 rounded text-center' style={{border:"2px solid black"}} onChange={(e)=>setReg_id(e.target.value)}></input>
        
        </Col>
        </Col>

        <Col md={2}>
          <div className='m-0'>
              <button  className='scanBtn py-2 px-3 rounded ' style={{backgroundImage:"linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",border:"1px solid",color:"white"}} onClick={handleDownload}>
              <b>Download Excel File</b>
              </button>
           </div>
        </Col>
     </Row>

     <Row className='m-0 p-md-5 pt-md-0 mb-5'>
        <Table striped bordered hover size="sm" className='shadow'>
        <thead>
            <tr className='text-center ' >
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>Sr.no</th>
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>Reg_Id</th>
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>Candidate Name</th>
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>Name-Receipt Brought By</th>
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>Mobile No.</th>
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>Time & Date</th>
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>No. Of Person</th>
            <th style={{backgroundColor:"rgb(134, 28, 18)",color:"white"}}>Stall No.</th>
            </tr>
        </thead>

      

        <tbody>
        {
            Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

              return (
                <>
            <tr className='text-center'>


            <td>{value.id}</td>
            <td>{value.reg_id}</td>
            <td>{value.Candidate_Name}</td>
            <td>{value.Name_Receipt_Brought_By}</td>
            <td>{value.Mobile_No}</td>
            <td>{value.dateTime}</td>
            <td>{value.No_of_person}</td>
            <td>{value.table_no == 0 ? "Admin" : value.table_no}</td>

            </tr>
            </>
              )
            })}
        </tbody>
     </Table>
     </Row>


         {/* -----------------footer login --------------------------- */}
      <div className='m-0 fixed-bottom '>
        <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{boxSizing:"border-box"}}>
          <Row>
            <Col>
            <div className='text-center' style={{display:"flex", justifyContent:"center", color:"white", color: "rgb(134, 28, 18)"}}>
                &copy; <b>2024 Jain Boarding | Managed By : TechnoAarv Solution</b>
            </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ============== Modal ==================== */}

      <Modal size='xl' show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
    <div className="row m-0">


     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
        {/* <div className="col-12 col-sm-1"></div> */}
        <div className="col-12 ">
        
            <MDBDataTable
              className=' custom-datatable'
              striped
              bordered
              hover
              small
              data={{ columns, rows: customRows }}
              style={{ textAlign: "center", fontSize: "14px",}}/>
        </div>
        {/* <div className="col-12 col-sm-1"></div> */}
    </Row>

    </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"rgb(134, 28, 18)",color:"white",border:"0px"}} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutModal} onHide={handleCloseLogoutModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoutModal}>
            Cancel
          </Button>
          <Button style={{backgroundColor:"#861c12",border:"1px solid #861c12"}} onClick={handleConfirmLogout}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>


    </Container>
  )
}

export default ScanPage



import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminLogin from './AdminComponents/AdminLogin'
import AdminDashboard from './AdminComponents/AdminDashboard'; 
import EditMainPage from './AdminComponents/Members/EditProfilesComp/EditMainPage';
import AdminPaymentSummary from './AdminComponents/AdminPaymentSummary'; 
import AdminPrintReports from './AdminComponents/AdminPrintReports';
import ForgotPassword from './AdminComponents/ForgotPassword';
import MemberProfiles from './AdminComponents/Members/MemberProfiles';
import PhotoApproval from './AdminComponents/Members/PhotoApproval';
import DisabilityProfiles from './AdminComponents/Members/DisabilityProfiles';
import InactiveMembers from './AdminComponents/Members/InactiveMembers';
import PendingPaymentMembers from './AdminComponents/Members/PendingPaymentMembers';
import PaymentSuccessMembers from './AdminComponents/Members/PaymentSuccessMembers';


import Login1 from './Login1';
import Registration1 from './UserComponents/LoginNavComponents/Registration1';
// import HorizontalLinearStepper from './UserComponents/RegistrationFromComp/HorizontalLinearStepper';
import Dashboard from './UserComponents/Dashboard';
import UserForgotPassword from './UserForgotPassword';
import UserMasterHeader from './UserComponents/UserCommonComponent/UserMasterHeader';
import UserMasterFooter from './UserComponents/UserCommonComponent/UserMasterFooter';
import TermsAndCondition from './UserComponents/LoginNavComponents/TermsAndCondition';
import PrivacyPolacy from './UserComponents/LoginNavComponents/PrivacyPolacy'
import StepperForm from './UserComponents/RegFormComp/StepperForm';
import FourthPage from './UserComponents/RegFormComp/FourthPage';
import Payment from './UserComponents/Payment';
import SecondPage from './UserComponents/RegFormComp/SecondPage';
import ThirdPage from './UserComponents/RegFormComp/ThirdPage';
import PaymentRecipt from './UserComponents/PaymentRecipt';
import MainPage from './UserComponents/MainPage';
import PaymentSuccess from './PaymentHandler/PaymentSuccess';
import PaymentFail from './PaymentHandler/PaymentFail';
import ViewProfile from './UserComponents/ViewProfile';
import AlreadyDonePage from './BarCodeLogin/AlreadyDonePage';
import ScanPage from './BarCodeLogin/ScanPage';
import DataPage from './BarCodeLogin/DataPage';
import LoginBarCode from './BarCodeLogin/LoginBarCode';
import AdminPaymentReceipt from './AdminComponents/Members/EditProfilesComp/AdminPaymentReceipt';
import Cancelation from './UserComponents/LoginNavComponents/Cancelation';
import ReturnAndRefundPrivacy from './UserComponents/LoginNavComponents/ReturnAndRefundPrivacy';
import PaymentPendingMemberOnline from './AdminComponents/Members/PaymentPendingMemberOnline';
import PaymentApproval from './AdminComponents/Members/PaymentApproval';
import EducationMaster from './AdminComponents/Masters/EducationMaster';
import SubEducationMaster from './AdminComponents/Masters/SubEducationMaster';
import SalaryMaster from './AdminComponents/Masters/SalaryMaster';
import SubCasteMaster from './AdminComponents/Masters/SubCasteMaster';
import PaymentNewOnline from './UserComponents/PaymentNewOnline';
import AdminReportCount from './AdminComponents/AdminReportCount';
import AdminAddressBookReport from './AdminComponents/AdminAddressBookReport';
import UserMemberProfiles from './UserComponents/UserMemberProfiles';
import Feedback from './AdminComponents/Feedback';





function App() {

  return (
    <>
     
    <Routes>
    
      <Route path="/" element={<Login1 />}/>
      <Route path="/registration1" element={<Registration1 />}/>
      {/* <Route path="/registrationForm" element={<HorizontalLinearStepper />}/> */}
      <Route path="/userDashboard" element={<Dashboard/>}/>
      {/* <Route path="/user/registrationForm" element={<HorizontalLinearStepper/>}/> */}
      <Route path="/user/forgotpassword" element={<UserForgotPassword />}/>
      <Route path="/userMasterHeader" element={<UserMasterHeader/>}/>
      <Route path="/userMasterFooter" element={<UserMasterFooter/>}/>
      <Route path="/terms&Condition" element={<TermsAndCondition/>}/>
      <Route path="/privacyPolicy" element={<PrivacyPolacy/>}/>
      <Route path="/user/regForm" element={<StepperForm/>}/>
      <Route path="/user/regForm/exp" element={<SecondPage/>}/>
      <Route path="/user/regForm/img" element={<ThirdPage/>}/>
      <Route path="/user/regForm" element={<StepperForm/>}/>
      <Route path="/payment" element={<FourthPage/>}/>
      <Route path="/paymentNew" element={<PaymentNewOnline/>}/>
      {/* <Route path="/payment2" element={<Payment/>}/> */}
      <Route path="/paymentRecipt" element={<PaymentRecipt/>}/>
      <Route path="/mainpage" element={<MainPage/>}/>
      <Route path="/payment/success" element={<PaymentSuccess/>}/>
      <Route path="/payment/fail" element={<PaymentFail/>}/>
      <Route path="/viewProfile" element={<ViewProfile/>}/>
      <Route path="/loginBarCode" element={<LoginBarCode/>}/>
      <Route path="/scanPage" element={<ScanPage/>}/>
      <Route path="/alreadyDonePage" element={<AlreadyDonePage/>}/>
      <Route path="/dataPage" element={<DataPage/>}/>
      <Route path="/Cancelation" element={<Cancelation/>}/>
      <Route path="/ReturnAndRefundPrivacy" element={<ReturnAndRefundPrivacy/>}/>
      <Route path="/UserMemberProfiles" element={<UserMemberProfiles/>}/>


      


      <Route path="/admin" element={<AdminLogin />}/>
      <Route path="/admin/dashboard" element={<AdminDashboard />}/>
      <Route path="/admin/ep1" element={<EditMainPage />}/>
      <Route path="/admin/r1" element={<AdminPrintReports />}/>
      <Route path="/admin/r2" element={<AdminReportCount />}/>
      <Route path="/admin/r3" element={<AdminAddressBookReport />}/>
      <Route path="/admin/ps1" element={<AdminPaymentSummary />}/>
      <Route path="/admin/forgotpassword" element={<ForgotPassword />}/>
      <Route path="/admin/member/mp" element={<MemberProfiles/>}/>
      <Route path="/admin/member/pa" element={<PhotoApproval/>}/>
      <Route path="/admin/member/dp" element={<DisabilityProfiles/>}/>
      <Route path="/admin/member/im" element={<InactiveMembers/>}/>
      <Route path="/Feedback" element={<Feedback/>}/>
      
      {/* <Route path="/admin/member/ppm" element={<PendingPaymentMembers/>}/> */}

      {/* =====Online- (New Code Start) ========*/}
      <Route path="/admin/member/paymentapprv" element={<PaymentApproval/>}/>
      <Route path="/admin/member/ppmOnline" element={<PaymentPendingMemberOnline/>}/>
      {/* =====Online- (New Code End) ========*/}


      <Route path="/admin/member/psm" element={<PaymentSuccessMembers/>}/>
      <Route path="/admin/members/update" element={<EditMainPage/>}/>
      <Route path="/admin/paymentReceipt" element={<AdminPaymentReceipt/>}/>
      <Route path="/admin/masters/edu" element={<EducationMaster/>}/>
      <Route path="/admin/masters/subedu" element={<SubEducationMaster/>}/>
      <Route path="/admin/masters/salary" element={<SalaryMaster/>}/>
      <Route path="/admin/masters/subCaste" element={<SubCasteMaster/>}/>
      
    </Routes>



  </>
  );
}

export default App;


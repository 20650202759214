import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../UserComponents/UserComponent.css';
import { useNavigate } from 'react-router-dom';
import { green } from '@mui/material/colors';
import afterlogo from '../../assets/login-after.png';
import Card from 'react-bootstrap/Card';
import { PaymentAddApi, PaymentUpdate, RegistrationSelect, RegistrationStop } from '../../AllApi/UserApi/UserApi';
import axios from 'axios';
import UserMasterHeader from '../UserCommonComponent/UserMasterHeader';
import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';
import { toast } from 'react-toastify';
import { set } from 'react-hook-form';
import { memberImgPath } from '../../AllApi/AdminApi/AdminApi';
import { Modal } from 'antd';



const FourthPage = () => {

   // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

//  const [disability,setdisability]=useState(false)
 const [lockDateOk,setlockDateOk]=useState(false)   

  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const member_id = userdata.id


  const [Userdata, setUserdata] = useState([])



  // useEffect(() => {
  //   Getdata();
  // }, []);


  const getUserData = () => {


    axios.get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])

  // -----------handle checkbox-----------
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [open, setOpen] = useState(false);

  const hideModal = () => {
    setOpen(false);
    logoutFun(); 
  };

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/");    
  }

  
  const handleNextClick = () => {

    // Here you can add your logic to navigate to the next page
    // if the checkbox is checked.
    if (isChecked) {


      if (Userdata && Array.isArray(Userdata)) {


        Userdata.forEach((value) => {
          if ((value.marital_status == "2" && value.gender == 2) || value.disability == "2") {

            setOpen(true);
            // setdisability(true)
            // toast.success('Your Receipt Will be Send On Mail After Admin Approval!', {
            //   position: "top-right",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            // });
            

          }
          else {
            navigate('/paymentNew');
          }
        }
        );

      }
    }
  
  }

  const regFun = () => {

          const state = 1
          navigate('/user/regForm', { state: { state } })
      
  
      }

  const regPrint = () => {
    navigate('/payment')
  }



  //--------------------------CSS---------------------------------
  const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "14px" }
  const style = { fontSize: "13px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "13px",wordWrap: 'break-word' }
  const style5 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "12px",flexDirection:"column",  wordWrap: 'break-word'  }

  return (

    <Container fluid className='m-0 p-0'>
      <div className='m-0 p-0'>
        <UserMasterHeader />
      </div>

      <Container className='container-fourth rounded p-3 my-3 p-md-5 '>
        <div className='rounded shadow  px-3 pb-2 py-sm-5' style={{ backgroundColor: "white" }} >
          <Row className='mb-2'>
            <Col>
              <div className='mt-5 mt-md-0' style={{ display: "flex", justifyContent: "center", textAlign: "center", color: "red" }}>
                <h5><b>The following text is to be printed in the main booklet as it is. Please make sure before proceeding... <br /> (खालील मजकूर आहे तसा मुख्य पुस्तिकेमध्ये छापला जाणार आहे. कृपया पुढे जाण्याअगोदर खात्री करावी...)</b></h5>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={afterlogo} />
              </div>
            </Col>  
          </Row>



          {
            Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

              return (
                <>
                  <div className='row'>
                     <div className='col-12 col-lg-1'></div>

                     <div className='col-12 col-lg-10 p-0' style={{ backgroundColor: "white", overflow: "auto" }} >

                        <div className='p-2' style={{ backgroundColor: "#f796ec", display: "flex", alignItems: "center", width: "1000px" }}>
                          <div className='col-3'></div>
                          <div className='col-9 me-3' style={{ alignItems: "center", display: "flex" }}>
                            <div className='col-9' style={{ margin: "0px 0px 0px -70px", alignItems: "end", }}><p style={{ fontWeight: "bold", fontSize: "20px", }}>{value.en_fname}/{' '}{value.en_fatherfname}{' '}{value.en_fathermname}{' '} {value.en_fatherlname} </p></div>

                            <div className='col-3 me-5' style={{ textAlign: "center", padding: "6px", border: "2px solid white", borderRadius: "50px", backgroundColor: "white", margin: "0px 0px 0px 50px" }}><label style={{ fontWeight: "bold", }}>SUP2024/{value.member_id}</label></div>
                          </div>
                        </div>

                        <div className=' d-flex p-0' style={{ border: "2px solid #c42fb2", borderTopColor: "#f796ec", width: "1000px", backgroundColor: "white" }}>
                          <div className='col-2 p-2' style={{ justifyContent: "center", display: "flex" }}>
                            <img src={`${memberImgPath}/${value.image}`} alt={`${value.en_fname}'s Photo`} style={{ width: "100%", height: "200px", border: "1px solid grey" }} />
                          </div>

                          <div className='col-10 px-4 py-2'>

                            <div className='row p-0'>
                              <div className='col-8 p-0 pt-2' style={{ lineHeight: "10px" }}>
                                <p style={{ fontSize: "15px", lineHeight: "18px" }}><b>Add : </b>{' '} {value.en_address}{' '}&nbsp;<b>Village/City : </b>{value.en_village}{' '}&nbsp;<b>Tal : </b>{value.talukaenname}{' '}&nbsp;<b>Dist : </b>{value.districtenname} {' '}&nbsp;<b>State : </b>{value.stateenname}</p>
                                <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Mob : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                                <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Occupation : </b>{' '}{value.occupation},&nbsp;{value.occupation_detail}</p>
                              </div>

                              <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
                                
                                    <div className='d-flex mb-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                      <div style={{ padding: "10px 15px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Brother</b></div>

                                      <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                              
                                      
                                          <span style={{ fontSize: "13px" }}><b>Married- </b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                        
                                        
                                          <span style={{ fontSize: "13px" }} className='ms-4'><b> Unmarried- </b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                      

                                
                                      </div>
                                      
                                    </div>
                                  

                                  <div className='d-flex mt-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                      <div style={{ padding: "10px 10px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Sister</b></div>

                                      <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                                          <span style={{ fontSize: "13px" }}><b>Married- </b>{value.s_married > 0 ? value.s_married: '-' }</span>
                                          <span style={{ fontSize: "13px" }}  className='ms-4'><b> Unmarried- </b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span> 
                                      </div>

                                  </div>
                                  
                              </div>
                            </div>


                            <div className="row p-0 d-flex mt-2">
                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>DOB & Time</b></div>
                                <div style={style2}> <p style={style4}>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Height & Comp.</b></div>
                                <div style={style2}> <p style={style4}>{value.height}</p> <p style={style4}>{value.complextion}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Education</b></div>
                                <div style={style2}> <h6 style={style5} className='text-center'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                                </div>
                              </div>

                              <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Annual Income & Assets</b></div>
                                <div style={style2}> <h6 style={style4}>{value.amount}</h6>
                                  { value.farm_g > 0 || value.farm_ac > 0 ? (
                                    <>
                                      <h6 style={style4}><b>Farm -&nbsp;</b>
                                      {value.farm_ac > 0 ? (
                                    <span> {value.farm_ac}&nbsp; acre{' '}</span>
                                      ) : null}
                                      {value.farm_g > 0 ? (
                                        <span style={style3}>{' '}&nbsp; {value.farm_g} &nbsp; gunthe{' '}</span>
                                        ) : null
                                      }
                                      </h6>
                                    </>
                                  ) : null}

                                  <span className='text-center' style={style4}>{value.other_property}</span></div>
                              </div>


                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"93px"  }}>
                                <div style={{ fontSize: "14px", width: "100%", padding: "5px 0px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }}><b>SubCaste </b></div>
                                <div style={style2}> <p style={{lineHeight:"18px",fontSize:"13px"}}>{value.subcastename}</p></div>
                              </div>

                              <div className='p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"108px" }}>
                                <div style={style}><b>Expectations</b></div>
                                <div style={style2}> <p style={{lineHeight: "20px", fontSize: "13px",display:"flex",flexDirection:"column",textAlign:"center",margin:"0px 12px",flexWrap:"wrap"}}>{' '}{value.exp_housewife === 1 ? 'Housewife ' : null}{value.exp_compatible === 1 ? 'Compatible ' : null}{value.exp_farmer === 1 ? 'Farmer ' : null}{value.exp_serviceman === 1 ? 'Service ' : null}{value.exp_businessman === 1 ? 'Business ' : null}</p></div>
                              </div>

                            </div>
                          </div>
                        </div>

                    </div>


                     <div className='col-12 col-lg-1'></div>
                  </div>
                 

                  <div className='row p-0'>
                    <div className="col-12 col-md-3"></div>
                    <div className='col-12 col-md-6' style={{ justifyContent: "center", display: "flex" }}>
                      <button type="button" className='rounded m-2' style={{ padding: "4px 15px", backgroundColor: "#ff7b00", border: "0px", color: "white", fontSize: "17px" }} onClick={regFun} > <b>Edit Profile</b></button>

                      {/* <button  className='rounded m-2' style={{ padding: "4px 22px", backgroundColor: "green", border: "0px", color: "white",fontSize: "17px" }} onClick={regPrint} > <b>View Profile For Print</b></button> */}
                    </div>
                    <div className="col-12 col-md-3"></div>
                  </div>
                </>
              )
            })}

       {/* { disability === true ? ( */}
            {/* <div className='row m-0 p-0 m-sm-0 pt-2 px-md-3 d-flex'>
            <div className='col-11 px-2 pt-5'>
              <p><b>Wait For Admin Approval <br /> (आयोजकांच्या मंजुरीची प्रतीक्षा करा)</b></p>
            </div>            
          </div> */}
          {/* ):null
       } */}
          {/* ---------second row------------ */}
          {/* { disability === false ?( */}
          <div className='row m-0 p-0 m-sm-0 pt-2 px-md-3 d-flex'>
            <div className='col-1 px-2 pt-5 '>
              <input className='me-3'
                type="checkbox"
                name="check"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className='col-11 px-2 pt-5'>
              <p><b>I agree to all the rules of the said melava and I will follow them. I assure you that the information filled in the said form is completely true and you can print it in your Parichay Pustika or give it to the prospective candidates. I will be fully responsible for that. The management or the board will not be responsible for the same. I will not allow all the office bearers of the bride and groom gathering to be harmed by any dispute. <br /> (सदर मेळाव्या विषयाचे सर्व नियम मला मान्य आहेत व मी त्याचे पालन करेन. मी आपणास असे आश्वासित करतो की सदर फॉर्म मध्ये भरलेली माहिती पूर्णतः खरी असून ती आपण आपल्या परिचय पुस्तिकेत छापू शकता किंवा अपेक्षित उमेदवारांना देऊ शकता. त्यासाठी मी पूर्णतः जबाबदार राहीन. संयोजक अथवा मंडळ त्यासाठी जबाबदार राहणार नाही. मी वधू-वर मेळाव्याचे सर्व पदाधिकारी यांना कोणत्याही विवादामुळे नुकसान होऊ देणार नाही.)</b></p>
            </div>

            <div className='col-12 ms-md-5'>
              <button className='rounded px-3 py-1 ms-md-4'
                // style={{padding:"7px 10px", backgroundColor:"rgb(134, 28, 18)",border:"0px",color:"white",fontSize:"20px"}}
                onClick={handleNextClick}
                disabled={!isChecked}
              >
                Confirm and proceed to payment
              </button>
            </div>

          </div>
          {/* ):null} */}


        </div>
      </Container>

         <Modal
            title="Payment Successful !"
            open={open}
            closable={false}
            footer={[
            <button style={{padding:"5px 15px",border:"1px solid",borderRadius:"5px",backgroundColor:"#790903",color:"white"}} key="ok" onClick={hideModal}>
              Ok
            </button>
          ]}  
          >
            <p className='text-danger'> नोंदणी शुल्क यशस्वीरीत्या भरले गेले आहे, आ डमीन मंजुर करेपर्यंत प्रतीक्षा करा (किमान २४ तास).</p>
            <p className='text-danger'>Payment successfully submited, wait for admin approval, requires min 24 hrs.</p>
          </Modal>


      <div><UserMasterFooter /></div>
    </Container>

  )
}

export default FourthPage





import React, { useState,useEffect } from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './UserForgotPass.css';
import { toast } from "react-toastify";

const UserForgotPassword = () => {

    const navigate=useNavigate();
    const [email, setEmail] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [isValid, setIsValid] = useState(true);
   

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setIsValid(true);
  };

  const handleBirthDate = (e) => {
    setBirthDate(e.target.value);
    setIsValid(true);
  };

  const handleForgotPassword = () => {

    if (email === "" || birthDate === "") {
        setIsValid(false); 
        return;
      }

    axios
      .post("https://reqres.in/api/forgot-password", {
        email: email,
        birthDate: birthDate,
      })
      .then(response => {
        const { status } = response.data;

      if (status == "SUCCESS") {
        toast.success("New password is successfully sent to your registered email.");
      } else if (status == "NOTEXIST") {
        toast.error("Invalid Username or Birth Date!");
      } else {
        toast.warn("Unexpected response. Please try again later.");
      }
      })
      .catch(error => {
        toast.error('Error Occurred !..')
      });

      navigate("/");
  };
      
  
  return (

<div className='forgot-container m-0 p-0'>
<div className="row m-0">
    <div className="screen">
        <div className="screen__content m-0">
            <div className="social-login" >
                <h6 style={{fontWeight:"700",}}>FORGOT PASSWORD??</h6>
            </div>
            <form className="login m-0">
                <div className="login__field">
                    <i className="login__icon fas fa-user"></i>
                    <input type="text" className="login__input" placeholder="Username" style={{color:"black",placeholderColor:"black"}} value={email}
                    onChange={handleEmail} />
                </div>
                <div className="login__field">
                    <i className="login__icon fas fa-lock"></i>
                    <input type="date" className="login__input" placeholder="Date of birth" value={birthDate}
                   onChange={handleBirthDate} style={{placeholderColor:"grey"}}/>
                </div>
                {isValid ? null : <div style={{ color: "red", fontSize: "12px" }}>All fields are compulsory*</div>}
                <button className="button login__submit" onClick={handleForgotPassword}>
                    <span className="button__text">Send Password</span>
                    <i className="button__icon fas fa-chevron-right"></i>
                </button>
            </form>
        </div>
        <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
        </div>
    </div>
</div>
</div>
  )
}

export default UserForgotPassword
import React, { useEffect, useState } from 'react';
import logonew from '../assets/logonew.jpg';
import {useNavigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import AdminMasterFooter from './AdminMasterFooter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import './AdminMasterHeaderCSS.css'
import { Button, Modal } from 'react-bootstrap';

const AdminMasterHeader = () => {

    
  const navigate=useNavigate()

  const checkLogin = () => {
    const getSessionval = sessionStorage.getItem('userdata')
    if (!getSessionval) {
      navigate('/')
    }
   
  }

  useEffect(() => {
    checkLogin()
  }, [])


  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem('userdata');
    sessionStorage.clear();
    navigate('/admin');
  };

  const handleCloseModal = () => setShowLogoutModal(false);
  const handleShowModal = () => setShowLogoutModal(true);

      
  return (
    <>

        <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col> 
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={160} height={130}/>
            </div> 
       </Col> 
        <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={handleShowModal}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "#660505",paddingBottom:"15px",}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233,224,227)",}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto"
            navbarScroll
          >
            <Nav.Link href="/#/admin/dashboard" className=' mx-2 text-white'>Home</Nav.Link>

            <NavDropdown  id="basic-nav-dropdown" className=' mx-2 text-white'
              title={<span className="text-white my-auto">Members</span>}>
              <NavDropdown.Item href="/#/admin/member/mp" className="dropdown-item">Member Profiles</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/paymentapprv" className="dropdown-item">Payment Approval</NavDropdown.Item>             
              <NavDropdown.Item href="/#/admin/member/pa" className="dropdown-item">Photo Approvals</NavDropdown.Item>
              {/* <NavDropdown.Item href="/#/admin/member/dp" className="dropdown-item">Disability Profiles</NavDropdown.Item> */}
              <NavDropdown.Item href="/#/admin/member/im" className="dropdown-item">Inactive Members</NavDropdown.Item>

              {/* //==========(Online - New Code Start)==========// */}
              <NavDropdown.Item href="/#/admin/member/ppmOnline" className="dropdown-item">Payment Pending Members</NavDropdown.Item>
              {/* //==========(Online - New Code End)==========// */}

              <NavDropdown.Item href="/#/admin/member/psm" className="dropdown-item">Payment Success Members</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown  id="basic-nav-dropdown" className=' mx-2 text-white'
              title={<span className="text-white my-auto custom-dropdown" >Masters</span>}>
               
              <NavDropdown.Item href="/#/admin/masters/edu" className="dropdown-item">Education Master</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/masters/subedu" className="dropdown-item">Subeducation Master</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/masters/salary" className="dropdown-item">Salary Master</NavDropdown.Item>
            </NavDropdown>

            {/* <Nav.Link href="/#/admin/ps1" className=' mx-2 text-white'>Payment</Nav.Link> */}
            {/* <Nav.Link href="/#/admin/afp" className=' mx-2 text-white'>Member Entry</Nav.Link> */}
            {/* <Nav.Link href="/#/admin/r1" className=' mx-2 text-white'>Report</Nav.Link> */}

            <NavDropdown  id="basic-nav-dropdown" className='mx-2 text-white '
              title={<span className="text-white my-auto custom-dropdown" >Report</span>}>               
              <NavDropdown.Item href="/#/admin/r1" className="dropdown-item">Print Report</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/r2" className="dropdown-item">Registration Count</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/r3" className="dropdown-item">Book Issue</NavDropdown.Item>
            </NavDropdown>

            {/* <Nav.Link href="/#/Feedback" className=' mx-2 text-white pe-5 me-5'>Feedback</Nav.Link> */}
            

          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
       

    </Container> 
    </div>

     {/* Logout Confirmation Modal */}
     <Modal show={showLogoutModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button style={{backgroundColor:"#660505",border:"1px solid #660505"}} onClick={handleLogout}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
   
    </>
  )
}

export default AdminMasterHeader
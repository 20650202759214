import React, {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container';
import {useNavigate} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../../assets/Mainlogo.jpg';
import Logo2 from '../../assets/logo2.png';
import { Button, Modal } from 'react-bootstrap';

const UserMasterHeader = () => {

  const navigate=useNavigate()

   // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

const [showLogoutModal, setShowLogoutModal] = useState(false);

const handleLogout = () => {
  sessionStorage.removeItem('userdata');
  sessionStorage.clear();
  navigate('/');
};

  const handleCloseModal = () => setShowLogoutModal(false);
  const handleShowModal = () => setShowLogoutModal(true);

  useEffect(() => {
    let token=sessionStorage.getItem("token");
    let userdata=sessionStorage.getItem("userdata");

    if(token==null || userdata==null){
      sessionStorage.clear()
      navigate("/")
    }
  }, [])
  
  return (
    <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col>

        <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link  onClick={handleShowModal}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> 

     {/* Logout Confirmation Modal */}
     <Modal show={showLogoutModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button style={{backgroundColor:"#660505",border:"1px solid #660505"}} onClick={handleLogout}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
       

    </Container> 
    </div>
  )
}

export default UserMasterHeader
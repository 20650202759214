import React, { useState,useEffect } from 'react'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminPhotoApproval, adminPhotoApproveList, findBookIssuedList, memberImgPath } from '../AllApi/AdminApi/AdminApi';
import './AdminLogin.css'
import { toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import { Row, Table } from 'react-bootstrap';
import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter';

const Feedback = () => {


   //----------------------------------------------------------------------------
  
    const [record, setRecord] = useState([])  
    const [data, setData] = useState([]);
    const [photoApprovalObj, setphotoApprovalObj] = useState()
    const [showLargePhoto, setShowLargePhoto] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedMember, setSelectedMember] = useState();
    const [showOperationButtons, setShowOperationButtons] = useState(false);
    const [Regid, setRegid] = useState() ;
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
  
  //----------------------------------API code------------------------------------------


  useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(findBookIssuedList,tokenConfig);
        console.log("===response===",response);
        
        setRecord(response.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
  fetchMemberData()
}, [])
  

    //--------------------------Image click code-----------------------------------
    const handleButtonClick = (member) => {
      setSelectedPhoto(member.photo);
      setSelectedMember(data);
      setShowOperationButtons(true); 
      setShowLargePhoto(true);
      setRegid(member.reg_id)
      const obj=record.find((item) => item.reg_id === member.reg_id);
      
    setphotoApprovalObj(obj)
    };
  
    const handleCloseLargePhoto = () => {
      setShowOperationButtons(false); 
      setShowLargePhoto(false);
      setSelectedPhoto(null);
      setSelectedMember(null);
    };

    const handleOperationClick = (operation) => {
      let updatedImageStatus 
      if (operation === 'approve') {
        updatedImageStatus = "1";
        
        toast.success('Photo Approved Successfully!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);

      } else if (operation === 'reject') { 
        updatedImageStatus = "2";

        toast.error('Photo Rejected !!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);

      }  
     
      const data={
        reg_id:photoApprovalObj.reg_id,
        image_status:updatedImageStatus 
      }
   
      axios.put(adminPhotoApproval,data)
      .then((res) => {
        
      })
      .catch((err) => console.log(err))
      handleCloseLargePhoto();
    };    

//=====================================DATA TABLE============================================
const footerStyle = {
  backgroundColor: '#660505',
  boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
  padding: '8px',
  textAlign: 'center',
  color: '#fff',
  fontSize: '12px',
  bottom: 0,
  width: '100%',
  letterSpacing: '1px',
}

const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'candidateName',
    sort: 'asc',
  },
  {
    label: 'Address',
    field: 'address',
    sort: 'asc',
  },
  {
    label: 'Mobile No-1',
    field: 'mobNo1',
    sort: 'asc',
  },
  {
    label: 'Mobile No-2',
    field: 'mobNo2',
    sort: 'asc',
  },
  {
    label: 'Feedback',
    field: 'feedback',
    sort: 'asc', 
  },

];


const customRows = record.map((item, index) => {
  const { candidateName,address, mobNo1 ,mobNo2,isIssued,} = item;

  const mainBookLabel = isIssued === 1 
  ? <div style={{ color: 'green', textAlign:"center"}}>✔</div> 
  : <div style={{ color: 'red' ,textAlign:"center"}}>✘</div>;

// const suppBookLabel = isIssued1 === 1 
//   ? <div style={{ color: 'green',textAlign:"center" }}>✔</div> 
//   : <div style={{ color: 'red',textAlign:"center" }}>✘</div>;

  
  return {
    srNo: index + 1,
    candidateName,
    address,
    mainBook:mainBookLabel,
    // suppBook:suppBookLabel,
    mobNo1,
    mobNo2,
  };
});


  return (
   
     <>
    <div className="row m-0" >
    <div className='p-0'> <AdminMasterHeader/> </div>

    <div className="filter-buttons row">
        <div className='col-12'>
        <h2 style={{ fontWeight: '550',textAlign:"start"}}>Feedback</h2>
        </div>
      </div>
    
     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
    <div className="col-12 col-sm-1"></div>
    <div className="col-12 col-sm-10" style={{overflow:"auto"}}>
        <MDBDataTable
          className='mb-5 custom-datatable'
          striped
          bordered
          hover
          small
          paging={!showLargePhoto}
          data={{ columns, rows: customRows }}
          style={{ textAlign: "start", fontSize: "14px" ,}}/>
    </div>
    <div className="col-12 col-sm-1"></div>
    </Row>
     
     
      {showLargePhoto && (
        <div className="large-photo-modal">
          <div className="modal-contentPhotoApp col-12 col-md-4">
          <span className="close1 ms-auto fs-1" onClick={handleCloseLargePhoto}>&times;</span>
            <div className='col-12' style={{textAlign:"center",display:"flex",height:"350px"}}>
            <img
              src={`${memberImgPath}/${photoApprovalObj.image}`}
              alt={`${photoApprovalObj.en_fname}'s Large Photo`}
              className="large-photo"
            />
          </div>
            {showOperationButtons && (
              <div className="operation-buttons">
                <button className='button1' id='btn' onClick={() => handleOperationClick('approve')}>Approve</button>
                <button className='button2' id='btn' onClick={() => handleOperationClick('reject')}>Reject</button>
              </div>
            )}
          </div>
        </div>
      )}

    </div>
    <AdminMasterFooter/>
     
    </>


  )
}

export default Feedback